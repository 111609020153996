import React from "react";
import Loader from "@/components/elements/Loader";

type Props = {};
const FullPageLoader: React.FC<Props> = ({}) => {
  return (
    <div className="d-flex justify-content-center h-100 w-100 position-fixed">
      <Loader />
    </div>
  );
};
export default FullPageLoader;
