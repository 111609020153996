import React from "react";
import cn from "classnames";
type Props = {
  children: React.ReactNode;
  cardHeader?: string;
  className?: string;
};
const Card: React.FC<Props> = ({ children, cardHeader, className }) => (
  <>
    {cardHeader && (
      <div className="card-header">
        <h4 className="card-title">{cardHeader}</h4>
      </div>
    )}
    <div className={cn("card",className)}>
      <div className="card-body">{children}</div>
    </div>
  </>
);

export default Card;
