import { ReactNode, useState, FC, PropsWithChildren, useMemo } from "react";
import cn from "classnames";

interface Props {
  isMobile?: boolean;
  children: ReactNode;
  className?: string;
  isVisibleByDefault?: boolean;
  thumbnail?: ReactNode;
}

const Promotion: FC<PropsWithChildren<Props>> = ({
  isMobile,
  children,
  className,
  thumbnail,
  isVisibleByDefault = false
}) => {
  const [isShown, setIsShown] = useState(isVisibleByDefault);
  const isVisible = useMemo(() => isShown || isMobile, [isShown, isMobile]);

  return (
    <div
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      className={cn("d-flex justify-content-between align-items-end", className, {
        shown: isVisible
      })}
    >
      {thumbnail}
      {isVisible && children}
    </div>
  );
};

export default Promotion;
