import { FC } from "react";
import Card from "@/components/common/Card";
import { Button, ButtonType } from "@/components/common/Button";
import { useRouter } from "next/router";
import { useAppSelector } from "@/hooks/redux";
import { getWalletLoadingSelector } from "@/redux/selectors/walletSelectors";
import Loader from "./Loader";

interface Props {
  avatar?: string;
  userName?: string;
}

const ProfilePromotion: FC<Props> = ({ avatar, userName }) => {
  const router = useRouter();
  const isLoading = useAppSelector(getWalletLoadingSelector);
  const { isLinked } = useAppSelector(state => state.wallet);

  return (
    <Card>
      <div className="d-inline-flex">
        <img src={avatar} alt="" className="avatar-img" />
        <h4 className="px-3 mt-4">Welcome, {userName}!</h4>
      </div>
      <p className="pt-3">
        Now that you&apos;ve successfully created your account and logged in, you can
        access all the features built on Verko.
      </p>
      {isLoading && <Loader />}
      {!isLoading &&
        (isLinked ? (
          <>
            <Button
              design={ButtonType.PRIMARY}
              className="me-3"
              onClick={() => router.push("/settings-profile")}
            >
              Settings
            </Button>
            <Button
              type="button"
              design={ButtonType.INFO}
              onClick={() => router.push("/characters")}
            >
              My NFTs
            </Button>
          </>
        ) : (
          <Button
            design={ButtonType.PRIMARY}
            className="me-3"
            onClick={() => router.push("/settings-profile")}
          >
            Connect Your Wallet
          </Button>
        ))}
    </Card>
  );
};

export default ProfilePromotion;
