import {useCallback, useEffect, useState} from "react";
import useDebounce from "@/hooks/useDebounce";

const MOBILE_SIZE = 768;
const DEBOUNCED_TIME = 300;

const useResize = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleResize = useCallback(() => {
    setScreenWidth(window.innerWidth);
    if (window.innerWidth < MOBILE_SIZE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  },[]);

  const debouncedHandleResize = useDebounce(handleResize, DEBOUNCED_TIME);

  useEffect(() => {
    if (window.innerWidth < MOBILE_SIZE) {
      setIsMobile(true);
    }

    if (window) {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [debouncedHandleResize]);

  return { isMobile, screenWidth };
};

export default useResize;
