import cn from 'classnames'
import React from 'react'

export enum ButtonType {
  OUTLINE_PRIMARY = 'btn-outline-primary btn-custom-outline',
  NO_OUTLINE_PRIMARY = 'btn-no-border',
  PRIMARY = 'btn-primary',
  SECONDARY = 'btn-secondary',
  INFO = 'btn-info',
  SVG = '',
}

interface Props {
  type?: 'button' | 'submit' | 'reset'
  design?: ButtonType
  className?: string
  onClick?: () => void
  children?: React.ReactNode
  disabled?: boolean
  formId?: string
}

const Button: React.FC<Props> = React.memo(
  ({
    type = 'button',
    design = ButtonType.PRIMARY,
    className,
    onClick,
    disabled = false,
    children,
    formId = '',
  }) => {
    return (
      <button
        /* eslint-disable-next-line react/button-has-type */
        type={formId ? 'submit' : 'button'}
        form={formId}
        disabled={disabled}
        className={cn(['btn', design, className])}
        onClick={e => {
          // If no formId passed call preventDefault to avoid submit
          !formId && e.preventDefault()
          onClick && onClick()
        }}
        onMouseLeave={e => e.preventDefault()}
      >
        {children}
      </button>
    )
  },
)

export { Button }
