import Layout from "@/components/layout/Layout";
import Promotion from "@/components/elements/Promotion";
import { useAppSelector } from "@/hooks/redux";
import FullPageLoader from "@/components/FullPageLoader";
import ProfilePromotion from "@/components/elements/ProfilePromotion";
import useResize from "@/hooks/useResize";
import { getUserSelector } from "@/redux/selectors/userSelectors";
import Image from "next/image";
import Promotion2Image from "@/public/images/CR-back.jpg";
import PromotionImage from "@/public/images/cover-cantina.jpeg";
import Promotion3Image from "@/public/images/bg1.jpg";
import { ImageLoadingType } from "@/types/enums";

const Home: React.FC = () => {
  const user = useAppSelector(getUserSelector);
  const { isMobile } = useResize();

  if (!user?.user_id) {
    return <FullPageLoader />;
  }

  return (
    <Layout headTitle="Dashboard" pageClass="dashboard">
      <div className="row">
        <div className="col-xxl-6">
          <Promotion
            className="promotion2"
            isMobile={true}
            isVisibleByDefault={true}
            thumbnail={
              <Image
                placeholder={ImageLoadingType.blur}
                src={Promotion2Image}
                layout="fill"
              />
            }
          >
            <div className="promotion-detail">
              <h1 className="text-white mb-3">NFT Reveal</h1>
              <p className="text-white">
                Description: Reveal your Mystery Box and discover your NFT
              </p>
              <a
                href="https://igo.verko.io/reveal"
                target="_blank"
                className="btn btn-primary me-3 btn-lg"
                rel="noreferrer"
                onMouseDown={e => e.preventDefault()}
              >
                Reveal
              </a>
            </div>
          </Promotion>
        </div>
        <div className="col-xxl-6">
          <Promotion
            isVisibleByDefault={false}
            className="promotion"
            isMobile={isMobile}
            thumbnail={
              <Image
                placeholder={ImageLoadingType.blur}
                src={PromotionImage}
                layout="fill"
              />
            }
          >
            <div className="promotion-detail">
              <h3 className="text-white mb-3">For the next billion Gamers</h3>
              <p>
                Cantina Royale is a top-down tactical arcade shooter game featuring both
                single-player and intense online multiplayer battles.
              </p>
              <a
                href="https://cantinaroyale.io/"
                target="_blank"
                className="btn btn-primary me-3"
                rel="noreferrer"
                onMouseDown={e => e.preventDefault()}
              >
                Website
              </a>
              <a
                href="https://whitepaper.cantinaroyale.io/"
                target="_blank"
                className="btn btn-info"
                rel="noreferrer"
                onMouseDown={e => e.preventDefault()}
              >
                Whitepaper
              </a>
            </div>
          </Promotion>
        </div>
        <div className="col-xxl-6 col-xl-12">
          <ProfilePromotion avatar={user.avatar} userName={user.userName} />
        </div>
        <div className="col-xxl-6 col-xl-12">
          <Promotion
            className="promotion3"
            isVisibleByDefault={true}
            isMobile={true}
            thumbnail={
              <Image
                placeholder={ImageLoadingType.blur}
                src={Promotion3Image}
                layout="fill"
                objectFit="cover"
              />
            }
          >
            <div className="promotion-detail">
              <h1 className="text-white mb-3">Marketplace - Coming Soon</h1>
            </div>
          </Promotion>
        </div>
      </div>
    </Layout>
  );
};

export async function getStaticProps(context: any): Promise<any> {
  return {
    props: {
      protected: true
    }
  };
}

export default Home;
